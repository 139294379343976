import { Navigate, useNavigate } from 'react-router-dom';
import { checkAuth } from '../auth';
import { useEffect } from 'react';

const ProtectedPoute = ({children}) => {
    // localStorage나 다른 상태 관리 도구에서 사용자 정보 확인
    //const user = localStorage.getItem('user');
    const user = checkAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if(!user) {
            console.log("[ProtectedRoute] 로그인 페이지로 리다이렉트");
            // 로그인 페이지로 리다이렉트하면서 현재 시도한 경로 정보 전달
            //return <Navigate to="/" replace state = {{from: window.location.pathname}} />;
            navigate('/', {replace: true});
        }
    }, [user, navigate]);

    return user ? children : null;
};

export default ProtectedPoute;