import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';

// 컴포넌트 스타일 적용
import '../css/Main.css';

// 메인 프레임에 보여지는 컴포넌트
import AicaFrame from './AicaFrame';  // AICA 페이지
import AiccFrame from './AiccFrame';  // AICC 페이지
import PatientListFrame from './PatientListFrame';  // 환자 목록 페이지 

// 서버 주소
// 서버 주소
//const SERVER = 'https://popbio.ai' // 운영
//const SERVER = 'http://118.67.131.227:8090' // 개발
const SERVER = 'http://localhost:8090' // 개발_Local

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Main = () => {
  /* ------------------------ State ------------------------ */
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userNo, setUserNo] = useState(null);

  const [activeFrame, setActiveFrame] = useState(null); // 현재 활성화된 프레임
  
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const navigate = useNavigate();

  /* ------------------------ Handler ------------------------ */
  // 프레임 변경 함수
  const handleFrameChange = (frame) => {
    console.log('프레임 변경 ::: ', frame);
    setActiveFrame(frame);
  }

  // 외부마이크 디바이스 조회
  const getDeviceList = async () => {
    const audioDevices = await getAudioDevices();
    setDeviceList(audioDevices);
  };
  

  /* ------------------------ useEffect ------------------------ */
  useEffect(() => {
    const initializeComponent = async () => {
      try {
        // 로그인 결과 조회
        if (location.state) {
          setUser(location.state.user);
          setUserNo(location.state.user.userNo);
          setActiveFrame(location.state.user.url);
          console.log('[Main.jsx] 로그인 결과 ::: ', location.state.user);
        }

        // TODO 상담실(AICA) 권한 사용자가 접근 시 외부마이크 디바이스 조회
        if (location.state.user.url === 'aica') {
          getDeviceList();
        }
      } catch (error) {
        console.error('[Main.jsx] 컴포넌트 초기화 실패 ::: ', error);
        //localStorage.removeItem('user');
        navigate('/', {replace: true});
      }
      
    };

    initializeComponent();
  }, [location]);

  
  /* ------------------------ 화면 렌더링 ------------------------ */  
  return (
    <div className="main-container">
      
      {/* 왼쪽 네비게이션 바 */}
      <nav className="nav-bar">
        {/* AICA 로고 아이콘 */}
        <div>
          <i className="icon-logo icon-img-aica-logo" /> {/* AICA 로고 아이콘 */}
        </div>
        {/* 홈 아이콘 */} 
        <div onClick={() => handleFrameChange(location.state.user.url)}>
          <i className="icon-default icon-img-home" />    
        </div>
         {/* 환자 목록 아이콘 */}
        {/* <div onClick={() => handleFrameChange('patientList')}>
          <i className="icon-default icon-img-patient-list" />
        </div> */}
      </nav>


      {/* 메인 프레임 영역 */}
      <div className="main-frame">
        {/* activeFrame이 null이면 아무것도 렌더링하지 않음 */}
        {activeFrame && (
          activeFrame === 'patientList' ? (
            <PatientListFrame />
          ) : activeFrame === 'aicc' ? (
            <AiccFrame />
          ) : (
            <AicaFrame />
          )
        )}
      </div>
    </div>
  );

};

export default Main;


// 외부마이크 디바이스 조회
const getAudioDevices = async () => {
  try {
    // 브라우저 호환성 체크를 위한 방어코드
    if (!navigator.mediaDevices?.getUserMedia) {
      navigator.mediaDevices = {};
      navigator.mediaDevices.getUserMedia = function(constraints) {
        const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        console.log('getUserMedia 함수 호출 ::: ', getUserMedia);

        if (!getUserMedia) {
          console.log('브라우저 호환성 체크 실패 ::: getUserMedia is not implemented in this browser');
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }

        return new Promise((resolve, reject) => {
          console.log('브라우저 호환성 체크 성공 ::: getUserMedia is implemented in this browser');
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      }
    }

    // 권한 상태 확인
    const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
    console.log('마이크 권한 상태:', permissionStatus.state);
    
    // 권한이 거부된 경우
    if (permissionStatus.state === 'denied') {
      alert('마이크 사용 권한이 거부되었습니다. 브라우저 설정에서 권한을 허용해주세요.');
      return;
    }
    
    // 미디어 장치 접근권한 요청
    await navigator.mediaDevices.getUserMedia({ audio: true })
      .catch(error => {
        console.log('마이크 권한이 거부됨:', error);
        throw new Error('마이크 접근 권한이 필요합니다.');
      });

    // 사용 가능한 모든 디바이스 조회
    const devices = await navigator.mediaDevices.enumerateDevices();

    // 오디오 입력 장치(마이크) 필터링
    const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
    console.log('사용 가능한 마이크 목록:', audioInputDevices);

    return audioInputDevices;
  } catch (error) {
    console.error('마이크 디바이스 조회 실패:', error);
    return [];
  }
};


