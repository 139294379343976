// 컴포넌트 스타일 적용
import '../css/PatientListFrame.css';

const PatientListFrame = () => {
  return (
    <div className="patient-list-container">
      <h2>환자 목록</h2>
    </div>
  );
};

export default PatientListFrame;