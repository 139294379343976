export const checkAuth = () => {
    const user = localStorage.getItem('user');
    if (!user) return false;
    
    try {
      const userData = JSON.parse(user);
      // 토큰 만료 시간 체크 등 추가 검증 로직
      return userData;
    } catch {
      return false;
    }
  };