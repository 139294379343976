import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useDebounce } from 'use-debounce';

import '../css/AicaFrame.css'; // 컴포넌트 스타일 적용
import prod from '../config/prod';  // 운영 서버 설정
import { common } from '../utils/common';

import patientListIcon from '../assets/patient_list.png';
import chatBubbleIcon from '../assets/chat_bubble.png';
import clinicalNotesIcon from '../assets/clinical_notes.png';
import defaultProfileIcon from '../assets/default_profile.svg';
////////////////////////////////////////////////////////////////////////////////////

function AicaFrame() {

  /* ----------------- 상태 변수 관리 ----------------- */
  const location        = useLocation();
  const [user, setUser] = useState(null);
  const userNo          = useRef(null);
  const webSocket       = useRef(null); // 웹소켓 연결 객체
  const [socket, setSocket] = useState(null);

  const [isRecording, setIsRecording] = useState(false);
  const [transcriptionText, setTranscriptionText] = useState('');
  const [summaryText, setSummaryText] = useState('');
  
  const mediaRecorder = useRef(null);
  const audioContext  = useRef(null);
  const processor     = useRef(null);

  const entireAudioChunks = useRef([]); // 전체 음성버퍼 저장  
  const audioChunks       = useRef([]); // 음성버퍼 저장

  const SAMPLE_RATE     = 8000; // 샘플링 주파수 (Hz)
  const CHUNK_RATE      = 1000; // 버퍼 크기 (ms)

  // 버퍼 관련 상태 추가
  //const [lastSendTime, setLastSendTime] = useState(Date.now());
  const lastSendTime = useRef(Date.now());
  
  const BUFFER_SIZE   = 8000; // 16kHz에서 1초 분량의 샘플 수
  const SEND_INTERVAL = 5000; // 음성 전송 간격 (10초)

  const animationFrameId = useRef(null); // 대화중인지 추척하는 함수의 에니메이션 설정 ID

  const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [startDt, setStartDt] = useState(null); // 상담 시작 시간
  const [endDt, setEndDt] = useState(null); // 상담 종료 시간

  const [patientList, setPatientList] = useState([]); // 환자 목록
  const [medicalHistory, setMedicalHistory] = useState([]); // 이전 진료 내역
  const [selectedHistory, setSelectedHistory] = useState(null); // 선택된 진료 내역
  
  // 상담종료 후 요약 수신 받을 동안 로딩 상태 관리
  const [isLoading, setIsLoading] = useState(false); 

  /* ----------------- 이벤트 함수 ----------------- */

  // 환자 추가 버튼 클릭 시 호출 이벤트
  const handleAddPatient = () => {
    console.log('환자 추가');
  };


  // 환자 검색 버튼 클릭 시 호출 이벤트
  const handleSearch = () => {
    console.log('환자 검색 이벤트 실행');
    return;

    // TODO: 환자관리 도입 시 검색 로직 구현

    // 팝업 상태 토글
    if (showSearchPopup) {
      setShowSearchPopup(false);
      setSearchTerm('');
      setSearchResults([]);
      //return;
    } else {
      setShowSearchPopup(true);
      // TODO: 실제 검색 로직 구현
      setSearchResults(patientList);
    }

    // 현재 검색어로 필터링된 결과 표시
    /*
    if (searchTerm) {
      const filtered = patientList.filter(patient => 
        patient.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filtered);
    } else {
      setSearchResults(patientList);
    }
    */
  };


  // 환자선택 처리 함수
  const handlePatientSelect = (patient) => {
    setSelectedPatient(patient);
    setShowSearchPopup(false);
  };

  
  // 환자 검색어 처리 함수
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value); // 검색어 상태만 업데이트

    // 여기서 직접 검색하지 않음

    /*
    // 검색어 입력 시 팝업창 자동으로 열기
    setShowSearchPopup(true);

    if (value.length >= 1) {
      const filtered = patientList.filter(patient => {
        const searchPatient = value.toLowerCase(); // 검색할 환자 정보
        const last4Phone = patient.phone.slice(-4); // 환자 전화번호 뒤 4자리

        return patient.name.toLowerCase().includes(searchPatient) || last4Phone.includes(searchPatient);
      });

      setSearchResults(filtered);
    } else {
      //setSearchResults(patientList);
      setSearchResults([]);
    }
    */
  };

  // 환자 검색 함수 (debounce 적용)
  const searchPatients = async (term) => {
    if (!term || term.length < 2) {
      setSearchResults([]);
      return;
    }
  
    try {
      const queryParams = new URLSearchParams({
        hospitalId: user?.hospitalId,
        searchTerm: term,
        limit: 10
      });
  
      const response = await fetch(`${prod.SERVER}/api/aica/search?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 필요한 경우 인증 헤더 추가
          // 'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('환자 검색 실패:', error);
      setSearchResults([]);
    }
  };


  // 2. 검색 로직 최적화
  /*
  useEffect(() => {
    // 최소 2글자 이상일 때만 검색 실행
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) {
      searchPatients(debouncedSearchTerm);
    } else {
      setSearchResults([]); // 검색어가 없거나 짧을 때는 결과 초기화
    }
  }, [debouncedSearchTerm]); // debouncedSearchTerm만 의존성으로 설정
  */


  // 웹소켓 연결 종료 함수
  const closeWebSocket = () => {
    /*
    if (webSocket.current) {
      webSocket.current.close();
      console.log('[AicaFrame.jsx] 웹소켓 연결이 종료되었습니다.');
    }
    */

    if (socket) {
      socket.close();
      console.log('[AicaFrame.jsx] 웹소켓 연결이 종료되었습니다.');
    }

    /*
    // 녹음 상태 업데이트
    if (isRecording) {
      setIsRecording(false);
      console.log('[AicaFrame.jsx] 녹음 상태 업데이트 ::: ', isRecording);
    }
    */

    // 버퍼 초기화
    entireAudioChunks.current = [];
    audioChunks.current = [];
  };


  // 녹음 시작
  const startRecording = () => {
    // 상담 시작 시각 기록
    const startTime = common.formatDateTime(new Date());
    setStartDt(startTime);
    console.log('[AicaFrame.jsx] 상담 시작 시각 ::: ', startTime);

    // 웹소켓 연결 종료
    closeWebSocket();

    // 상담 내용, 상담요약 초기화
    setTranscriptionText('');
    setSummaryText('');
  
    try {
      // 웹소켓 연결
      const socket = io(`${prod.SERVER}/aica-wss`, {
        query: { userNo: user?.userNo },
        reconnectionDelay: 1000,      // 재연결 대기 시간
        reconnectionDelayMax: 5000,   // 최대 재연결 대기 시간
        reconnectionAttempts: 10      // 재연결 시도 횟수
      });

      // 소켓 상태 검토
      if (!socket) {
        console.error('[AicaFrame.jsx] 소켓이 연결되지 않았습니다.');
        // TODO: 소켓 연결 실패 처리 (팝업 알림 등)
        return;
      }

      // 소켓 객체 저장
      setSocket(socket);


      // 리스너 - 웹소켓 연결 완료 
      socket.on('connection', async () => {
        try {
          // 오디오 컨텍스트 지원 여부 확인
          if (!window.AudioContext || !window.AudioWorklet) {
            console.error('AudioContext 또는 AudioWorklet을 지원하지 않음');
            return;
          }

          console.log('[AicaFrame.jsx] 웹소켓 연결 완료');

          // 마이크 권한 요청
          console.log('[AicaFrame.jsx] 마이크 권한 요청 중...');
          const stream = await navigator.mediaDevices.getUserMedia({ 
            audio: {
              sampleRate: SAMPLE_RATE,    // 원하는 샘플레이트
              channelCount: 1,            // 모노
              echoCancellation: true,     // 에코 제거
              noiseSuppression: true,    // 노이즈 제거
              autoGainControl: true,     // 자동 소음 제거
            }
          });

          if (!stream) {
            console.error('[AicaFrame.jsx] 마이크 권한 요청 실패');
            return;
          }

          // 마이크 정보 확인
          const tracks = stream.getAudioTracks();
          const mic = tracks[0];
          console.log('[AicaFrame.jsx] 마이크 정보 ::: ', mic.getSettings().deviceId);

          // 녹음 객체 생성
          mediaRecorder.current = new MediaRecorder(stream);

          // 오디오 컨텍스트 생성
          audioContext.current = new window.AudioContext({
            sampleRate: SAMPLE_RATE,
          });
          console.log('[AicaFrame.jsx] 녹음 객체 및 오디오 컨텍스트 생성 완료');

          // 오디오 처리 모듈 로드
          await audioContext.current.audioWorklet.addModule("./linear16-processor.js");

          // 오디오 소스 생성
          const source = audioContext.current.createMediaStreamSource(stream);
          
          // 오디오 처리 모듈 생성
          processor.current = new AudioWorkletNode(audioContext.current, "linear16-processor");
          
          // 녹음 상태 업데이트
          setIsRecording(true);
          
          // 오디오 처리 완료 이벤트 리스너
          processor.current.port.onmessage = (event) => {
            if (webSocket.current) {
              const currentTime = Date.now();
              const timeSinceLastSend = currentTime - lastSendTime.current;
              
              // 오디오 데이터 처리
              const chunkData = new Int16Array(event.data);
              
              // 안전하게 배열 크기 체크 후 추가
              if (audioChunks.current.length < 1000) { // 최대 버퍼 크기 제한
                audioChunks.current.push(chunkData);
                entireAudioChunks.current.push(chunkData);
              }
          
              // 시간 간격 체크
              if (timeSinceLastSend >= SEND_INTERVAL) {
                try {
                  // 전송할 데이터가 있는지 확인
                  if (audioChunks.current.length > 0) {
                    // 총 샘플 수 계산
                    const totalSamples = audioChunks.current.reduce((sum, chunk) => {
                      return sum + (chunk ? chunk.length : 0);
                    }, 0);
          
                    if (totalSamples > 0) {
                      // 데이터 병합
                      const mergedData = new Int16Array(totalSamples);
                      let offset = 0;
                      
                      audioChunks.current.forEach(chunk => {
                        if (chunk && chunk.length > 0) {
                          mergedData.set(chunk, offset);
                          offset += chunk.length;
                        }
                      });
          
                      // Base64 변환
                      const buffer = mergedData.buffer;
                      const base64Data = btoa(
                        String.fromCharCode.apply(null, new Uint8Array(buffer))
                      );
          
                      // 데이터 전송
                      webSocket.current.emit('audio-stream', {
                        userNo: user?.userNo,
                        audioData: base64Data
                      });
          
                      console.log('[AicaFrame.jsx] 오디오 데이터 전송 시각:', 
                        new Date(currentTime).toLocaleTimeString(),
                        '버퍼 크기:', totalSamples);
                    }
                  }
          
                  // 시간 업데이트 및 버퍼 초기화
                  lastSendTime.current = currentTime;
                  audioChunks.current = [];
          
                } catch (error) {
                  console.error('[AicaFrame.jsx] 오디오 데이터 처리 중 오류:', error);
                  audioChunks.current = []; // 에러 발생 시 버퍼 초기화
                }
              }
            }
          };

          // 사용자가 말하고있는지를 체크하는 코드
          const analyser = audioContext.current.createAnalyser();
          analyser.fftSize = 256;
          const dataArray = new Uint8Array(analyser.frequencyBinCount);

          source.connect(processor.current);
          processor.current.connect(audioContext.current.destination);

          source.connect(analyser);

          const detectTalking = () => {
              if (!webSocket.current) {
                return;
              }
    
              analyser.getByteFrequencyData(dataArray);
              const avgVolume =
                dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
    
              if (avgVolume > 50) {
                //setIsTalking(true);
                console.log('[AicaFrame.jsx] 대화중');
              } else {
                //setIsTalking(false);
                //console.log('[AicaFrame.jsx] 대화중이 아님');
              }
    
              animationFrameId.current = requestAnimationFrame(detectTalking);
          };

          detectTalking();

          // 녹음 종료 이벤트 리스너
          mediaRecorder.current.onstop = () => {
            console.log('[AicaFrame.jsx] 녹음 종료 이벤트 리스너 실행');
            if (processor.current && audioContext.current) {
              stream.getTracks().forEach((track) => track.stop());
              source.disconnect(processor.current);
              processor.current.disconnect(audioContext.current.destination);
            }
          };

          // 녹음 시작
          mediaRecorder.current.start(CHUNK_RATE);
          console.log('[AicaFrame.jsx] 녹음 시작');
        } catch (error) {
          console.error('[AicaFrame.jsx] 웹소켓 연결 오류:', error);
        }
      });


      // 리스너 - 음성 인식 결과 수신 이벤트
      socket.on('transcription', (data) => {
        console.log('받은 메시지:', data);

        // data.text로 접근하도록 수정
        if (data.status === 200) {
          setTranscriptionText(prevText => {
          // 이전 텍스트가 있으면 줄바꿈을 추가하고, 없으면 새로운 텍스트만
            return prevText ? `${prevText}\n${data.text}` : data.text;
          });
        }
      });


      // 리스너 - 웹소켓 연결 해제
      socket.onclose = () => {
        console.log('[AicaFrame.jsx] 웹소켓 연결 해제');

        // 리소스 해제
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
          animationFrameId.current = null;
        }

        if (processor.current) {
          processor.current.disconnect();
          processor.current = null;
        }

        if (audioContext.current) {
          audioContext.current.close();
          audioContext.current = null;
        }

        if (mediaRecorder.current) {
          mediaRecorder.current.stop();
          mediaRecorder.current = null;
        }
      };

      // 마이크 스트림 전송
      //webSocket.emit('audio-stream', stream);

      // 웹소켓 객체 저장
      webSocket.current = socket;
      console.log('[AicaFrame.jsx] 웹소켓 객체 저장 ::: ', webSocket.current);
    } catch (error) {
      console.error('[AicaFrame.jsx] 상담 시작 에러:', error);
    }
  };


  // 녹음 종료
  const stopRecording = async () => {
    console.log('[AicaFrame.jsx] 녹음 종료');

    try {
      setIsLoading(true); // 로딩 시작
      
      // 1. 상담 종료 시각 기록
      const endTime = common.formatDateTime(new Date());
      setEndDt(endTime);
      console.log('[AicaFrame.jsx] 상담 종료 시각 ::: ', endTime);
      
      // 웹소켓 연결 종료
      closeWebSocket();

      // 2. 상담 내용이 없으면 종료
      if (!transcriptionText.trim()) {
        console.log('[AicaFrame.jsx] 상담 내용이 없습니다.');
        return;
      }

      

      // 3. 상담 내용 서버로 전송
      const consultationData = {
        userNo: user?.userNo, // 로그인 사용자 번호
        patientName: selectedPatient?.patientName, // 환자 이름
        content: transcriptionText, // 상담 내용
        startDt: startDt, // 상담 시작 시각
        endDt: endTime // 상담 종료 시각
      };

      // 4. 상담 내용 서버로 전송
      const response = await fetch(`${prod.SERVER}/api/aica-wss/consultation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(consultationData)
      });

      // 5. 요약 내용 받아서 화면에 표시
      const { summary } = await response.json();
      setSummaryText(summary);

      // 6. 환자 목록과 진료 내역 갱신
      await Promise.all([
        fetchPatientList(user?.userNo),
        fetchMedicalHistory(user?.userNo, selectedPatient?.patientName)
      ]);
      
    } catch (error) {
      console.error('[AicaFrame.jsx] 상담 종료 에러:', error);
    } finally {
      setIsLoading(false); // 로딩 종료
      setStartDt(null);
      setEndDt(null);

      // 녹음 상태 업데이트
      if (isRecording) {
        setIsRecording(false);
        console.log('[AicaFrame.jsx] 녹음 상태 업데이트 ::: ', isRecording);
      }
    }
  };


  // 마이크 권한 요청 함수
  const getAudioMediaStream = async () => {
      try {
        // 마이크 권한 요청
        console.log('[AicaFrame.jsx] 마이크 권한 요청 중...');
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            sampleRate: SAMPLE_RATE,    // 원하는 샘플레이트
            channelCount: 1,            // 모노
            echoCancellation: true,     // 에코 제거
            noiseSuppression: true,    // 노이즈 제거
            autoGainControl: true,     // 자동 소음 제거
          }
        });

        // 마이크 정보 확인
        const tracks = stream.getAudioTracks();
        const track = tracks[0];

        // 마이크 권한 획득 성공
        /*
        console.log('[AicaFrame.jsx] 마이크 정보:', {
          deviceId: track.getSettings().deviceId,
          label: track.label,
          sampleRate: track.getSettings().sampleRate,
          channelCount: track.getSettings().channelCount,
          settings: track.getSettings(),
          constraints: track.getConstraints(),
          capabilities: track.getCapabilities()
        });
        */

        console.log('[AicaFrame.jsx] 마이크 권한 요청 완료 ::: ', track.getSettings().deviceId);

        return stream;
      } catch (error) {
        console.error('[AicaFrame.jsx] 마이크 권한 요청 실패:', error);
        return null;
      }
  };


  // 환자 목록 조회
  const fetchPatientList = async (userNo) => {
    try {
      const response = await fetch(`${prod.SERVER}/api/aica-wss/patientList`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userNo: userNo
        })
      });

      if (!response.ok) {
        throw new Error('환자 목록 조회 실패');
      }

      const result = await response.json();
      if (result.success) {
        console.log('[AicaFrame.jsx] 환자 목록 ::: ', result.data);
        setPatientList(result.data);

        // 첫번째 환자의 이전 진료 내역 조회
        fetchMedicalHistory(userNo, result.data[0].patientName);
      } else {
        throw new Error(result.message);
      };

      
    } catch (error) {
      console.error('[AicaFrame.jsx] 환자 목록 조회 실패:', error);
    }
  };


  // 이전 진료 내역 조회
  const fetchMedicalHistory = async (userNo, patientName) => {
    try {
      const response = await fetch(`${prod.SERVER}/api/aica-wss/medicalHistory`, {
        method: 'POST',
        headers: {  
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userNo: userNo,
          patientName: patientName
        })
      });

      if (!response.ok) {
        throw new Error('이전 진료 내역 조회 실패');
      }

      const result = await response.json(); 
      if (result.success) {
        console.log('[AicaFrame.jsx] 이전 진료 내역 ::: ', result.data);
        setMedicalHistory(result.data);
      } else {
        throw new Error(result.message);
      } 
    } catch (error) {
      console.error('[AicaFrame.jsx] 이전 진료 내역 조회 실패:', error);
    }
  };


  // 환자 선택 시 이전 진료 내역 조회
  const handleMedicalHistory = (patient) => {
    console.log('[AicaFrame.jsx] 환자 선택 ::: ', patient);

    setSelectedPatient(patient);
    fetchMedicalHistory(userNo.current, patient.patientName);
  };


  // 진료내역 선택 핸들러
  const handleHistorySelect = (history) => {
    setSelectedHistory(history);
    setTranscriptionText(history.content);    // 상담내용 textarea에 표시
    setSummaryText(history.summary);          // 상담요약 textarea에 표시
  };



  /* ----------------- useEffect ----------------- */
  useEffect(() => {
    // 로그인 ID 조회
    if (location.state) {
      setUser(location.state.user);
      userNo.current = location.state.user.userNo;
      console.log('[AicaFrame.jsx] 로그인 ID ::: ', userNo.current);
    }

    // 환자 목록 조회
    fetchPatientList(userNo.current);
  }, []);



  /* ----------------- useEffect ----------------- */
  useEffect(() => {
    // 상담 내용 스크롤

    // textarea 요소 찾기
    const textarea = document.querySelector('.chat-textarea');
    if (textarea) {
      // 스크롤을 맨 아래로 이동
      textarea.scrollTop = textarea.scrollHeight;
    }
  }, [transcriptionText]); // transcriptionText가 변경될 때마다 실행


  useEffect(() => {
    console.log('[AicaFrame.jsx] Recording 상태:', isRecording);
  }, [isRecording]);



  /* ----------------- 화면 렌더링 ----------------- */
  return (
    <div className="aica-container">
      
      {/* 상단 검색 영역 */}
      <div className="top-header">
        <div className={`search-box ${showSearchPopup ? 'popup-open' : ''}`}>
          <input 
            type="text"
            placeholder="환자 검색"
            maxLength={15} 
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button className="search-button" onClick={handleSearch}>
            <i className="icon-default icon-img-search" />
          </button>

          {/* 환자검색 팝업 */}
          {showSearchPopup && (
            <div className="search-popup">
              <div className="popup-content">
                {searchResults.map(patient => (
                  <div 
                    key={patient.id} 
                    className="search-result-item"
                    onClick={() => handlePatientSelect(patient)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="patient-name">{patient.name}</span>
                    <span className="patient-birth">{patient.birthDate}</span>
                    <span className="patient-phone">{patient.phone}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="profile">
          <img src={defaultProfileIcon} alt="profile" className="profile-image" />
          <span className="profile-status"></span>
        </div>

      </div>


      



      {/* 메인 영역 */}
      <div className="aica-main">
        {/* --------- 왼쪽 섹션 - 환자 목록 --------- */}
        <div className="left-section">
          {/* 환자 목록 */}
          <div className="patient-list-box">
            
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={patientListIcon}
                  alt="환자목록"
                  className="icon-mark"
                />
              </div>
              <h3>환자 목록</h3>
              <div className="spacer" />
              {/*<button className="add-button" onClick={handleAddPatient}>+</button>*/}
            </div>

            <div className="list-content">
              <div className="patient-list">
                {patientList?.map((patient, index) => (
                  <div 
                      key={index}
                      className={`patient-item ${selectedPatient?.patientName === patient.patientName ? 'selected' : ''}`}
                      onClick={() => handleMedicalHistory(patient)}
                  >
                      <span className="patient-name">{patient.patientName}</span>
                      <span className="patient-date">{new Date(patient.lastDate).toLocaleDateString()}</span>
                      {/*<span className="patient-status">대기중</span>*/}
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>



        {/* --------- 중앙 섹션 - 환자 정보 --------- */}
        <div className="middle-section">
          {/* 환자 정보 */}
          <div className="patient-info-box">
            
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={chatBubbleIcon}
                  alt="환자정보"
                  className="icon-mark"
                />
              </div>
              <h3>환자 정보</h3>
            </div>

            <div className="info-content">
              <div className="info-row">
                <div className="info-item">
                  <label style={{ fontWeight: 'bold' }}>환자 이름</label>
                  <input 
                    type="text" 
                    value={selectedPatient ? selectedPatient.patientName : ''} 
                    disabled={isRecording}
                    style={{
                      backgroundColor: isRecording ? '#f5f5f5' : 'white',
                      cursor: isRecording ? 'not-allowed' : 'text'
                    }}
                    onChange={(e) => {
                      if (selectedPatient) {
                        setSelectedPatient({
                          ...selectedPatient,
                          patientName: e.target.value
                        });
                      } else {
                        setSelectedPatient({
                          patientName: e.target.value
                        });
                      }
                    }}
                    maxLength={10}
                  />
                </div>
                
                {/* TODO 추후 환자관리 도입 시 사용 예정
                <div className="info-item">
                  <label style={{ fontWeight: 'bold' }}>생년월일</label>
                  <input 
                    type="text" 
                    value={selectedPatient ? selectedPatient.birthDate : ''} 
                    disabled 
                  />
                </div>
                */}
              </div>

              {/* TODO 추후 환자관리 도입 시 사용 예정
              <div className="info-row">
                <div className="info-item">
                  <label style={{ fontWeight: 'bold' }}>연락처</label>
                  <input 
                    type="text" 
                    value={selectedPatient ? selectedPatient.phone : ''} 
                    disabled 
                  />
                </div>
                <div className="info-item">
                  <label style={{ fontWeight: 'bold' }}>성별</label>
                  <div className="gender-options">
                    <label htmlFor="male">남성</label>
                    <input 
                      type="radio" 
                      name="gender" 
                      id="male" 
                      checked={selectedPatient?.gender === 'male'}
                      disabled 
                    />
                    <label htmlFor="female">여성</label>
                    <input 
                      type="radio" 
                      name="gender" 
                      id="female" 
                      checked={selectedPatient?.gender === 'female'}
                      disabled 
                    />
                  </div>
                </div>
              </div>
              */}

              {/* TODO 추후 환자관리 도입 시 사용 예정
              <div className="info-row">
                <div className="info-item full-width">
                  <label style={{ fontWeight: 'bold' }}>상담메모</label>
                  <textarea placeholder="상담 메모를 입력하세요" rows="4"></textarea>
                </div>
              </div>
              */}

              {/* TODO 추후 환자관리 도입 시 사용 예정
              <div className="info-row">
                <button className="save-button">저장</button>
              </div>
              */}

            </div>
          </div>

          {/* 이전 진료 내역 */}
          <div className="medical-history-box">
            
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={chatBubbleIcon}
                  alt="이전상담내역"
                  className="icon-mark"
                />
              </div>
              <h3>이전 상담 내역</h3>
            </div>
           
            {/* 진료 내역 내용 */}
            <div className="history-content">
              {medicalHistory.map((history, index) => (
                <div 
                  key={index} 
                  className={`history-item ${selectedHistory?.seqNo === history.seqNo ? 'selected' : ''}`}
                  onClick={() => handleHistorySelect(history)}
                >
                  <div className="history-date">
                    {common.formatDateTimeDisplay(history.startDt)}
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>



        {/* --------- 오른쪽 섹션 - 상담 영역 --------- */}
        <div className="right-section">
          
          {/* 상담 영역 */}
          <div className="aica-chat-content-box">
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={clinicalNotesIcon}
                  alt="상담"
                  className="icon-mark"
                />
              </div>
              <h3>상담</h3>
            </div>

            <div className="chat-buttons">
                <button 
                  className={`chat-button ${isRecording ? '' : 'active'}`}
                  onClick={startRecording}
                  disabled={isRecording || isLoading}>
                    상담시작
                </button>

                <button 
                  className={`chat-button ${isRecording ? 'active' : ''}`}
                  onClick={stopRecording}
                  disabled={!isRecording || isLoading}>
                    상담종료
                </button>
            </div>

            <div className="aica-chat-content">
              <textarea 
                className="aica-chat-textarea"
                placeholder="" 
                value={transcriptionText}
                readOnly
                />
            </div>
          </div>

          {/* 상담 내역 요약 */}
          <div className="chat-summary-box">
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={clinicalNotesIcon}
                  alt=""
                  className="icon-mark"
                />
              </div>
              <h3>상담 내역 요약</h3>
            </div>

            <div className="summary-content">
              {isLoading ? (
                <div className="loading-spinner">
                  <span>요약 생성 중...</span>
                  {/* 로딩 스피너 컴포넌트 추가 */}
                </div>
              ) : (
                <textarea
                  className="summary-textarea"
                  placeholder="상담 내용 요약"
                  value={summaryText}
                  readOnly 
                />
              )}
            </div>
          </div>

        </div>
      </div>



      
      
    </div>
  );
};

export default AicaFrame;
////////////////////////////////////////////////////////////////////////////////////