// App.js
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import WebSocketComponent from './components/WebSocketComponent';  // WebSocketComponent를 import
import Login from './components/Login';
import Main from './components/Main';
import ProtectedRoute from './components/ProtectedRoute';
import { checkAuth } from './auth';

function App() {

  console.log("[App.js] checkAuth() 결과 ::: ", checkAuth());

  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/" 
          element={checkAuth() ? <Navigate to="/main" /> : <Login />} 
        />
        
        <Route 
          path="/aicc" 
          element={<WebSocketComponent />} 
        />
        
        <Route 
          path="/aica" 
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/main" 
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          } 
        />

        {/*<Route 
          path="/main" 
          element={
            checkAuth() ? (
              <Main />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />*/}
        {/* 다른 라우트들 */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

/*
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/AICC" element={<WebSocketComponent />} />
      </Routes>
    </Router>
  );
}
*/


