export const aiccApi = {
    
    // 번호에 매칭되는 통화 기록 조회
    async fetchMessageList(SERVER, userNo, seqNos) {
        const URL = `${SERVER}/api/aicc-wss/messageList`
        
        try{
        console.log(`[WebSocketComponent] fetchMessageList() ::: userNo [${userNo}], seqNos[${seqNos}]`);
    
        const jsonData = {
            "userNo" : userNo,
            "seqNos" : Array.isArray(seqNos) ? seqNos : [seqNos]
        };
    
        const response = await fetch(URL, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        });
        console.log('[WebSocketComponent] fetchMessageList() 응답 :::', response);
    
        if (!response.ok) {
            throw new Error('[WebSocketComponent] fetchMessageList() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
        }
        
        } catch (error) {
        console.error('[WebSocketComponent] fetchMessageList() 에러 :::', error);
        }
    },
  
  
  // 전화번호로 매칭되는 통화 기록 조회2 (JSON)
  async fetchMessageListToJSON(SERVER, userNo, phoneNumber) {
    const URL = `${SERVER}/api/aicc-wss/messageList_json2`
    try{
      const jsonData = {
        "userNo"      : userNo,
        "phoneNumber" : phoneNumber
      };
  
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      // 호출 쪽의 socket.on('messageList_json2', 에서 응답 처리를 함.
      //console.log('[WebSocketComponent] fetchMessageListToJSON() 응답 :::', response);
  
      if (!response.ok) {
        throw new Error('[WebSocketComponent] fetchMessageListToJSON() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
      }
  
    } catch (error) {
      console.error('[WebSocketComponent] fetchMessageListToJSON() 에러 :::', error);
    }
  },

};
  