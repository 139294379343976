import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '/react/test/src/css/Login.css';
import '/react/test/src/css/Modal.css';
import Modal from 'react-modal';
import CryptoJS from 'crypto-js';
import prod from '../config/prod.js';  // 운영 서버 설정
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//const SERVER = 'https://popbio.ai' // 운영

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Login = () => {
    const [userId, setUserId]       = useState('');
    const [password, setPassword]   = useState('');
    const [error, setError]         = useState('');

    const navigate = useNavigate();

    // 알림 모달 표시
    const [isModalOpen, setIsModalOpen]     = useState(false);
    const [modalMessage, setModalMessage]   = useState('');

    // 모달 표시 함수
    const showModal = (message) => {
        setModalMessage(message);
        setIsModalOpen(true);
    };

    const handleIdChange = (event) => {
        setUserId(event.target.value);
    };

    /*
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    */

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    // 로그인 계정 정보
    const user = {
        "userId"    : userId,
        "password"  : CryptoJS.SHA256(password).toString()
    };

    // API를 호출하여 사용자 인증 처리
    const authenticateUser = async (userId, password) => {
        try {
            // 로컬 : 'http://localhost:7070/user/login'
            // 도커 : 'http://nodejs:8000/user/login'
            //console.log("로그인 시도")
            // https://popbio.ai/api/aicc/sceq
            
            //const URL = "https://popbio.ai/api/user/login"
            const URL = `${prod.SERVER}/api/user/login` // 로그인 API 호출
            //const URL_DEV = "http://118.67.131.227:8010/api/user/login"

            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user),
            });

            if (!response.ok) {
                throw new Error('네트워크 응답이 올바르지 않습니다.');
            }

            const data = await response.json();
            //console.log("로그인 결과");
            //console.log(data);
            
            // API 응답에서 성공 여부와 사용자 ID를 반환
            if (data.success) {
                const userData = { 
                    userNo: data.userNo,
                    userId: data.userId,
                    hospitalId: data.hospitalId,
                    name: data.name,
                    url: data.url,
                    token: data.token, // 서버에서 받은 토큰 저장
                    expriresIn: new Date().getTime() + (24 * 60 * 60 * 1000) // 24시간 후 만료
                };

                // localStorage에 토큰 저장
                localStorage.setItem('user', JSON.stringify(userData));
                return {success: true, ...userData};
            } else {
                return { success: false, message: data.message };
            }
        } catch (error) {
            console.error('인증 중 오류 발생:', error);
            return { success: false, message: '오류가 발생했습니다. 나중에 다시 시도해 주세요.' };
        }
    };

    // 로그인 이벤트
    const handleLogin = async (event) => {
        event.preventDefault();
        setError('');

        const result = await authenticateUser(userId, password);

        if (result.success) {
            // 인증 성공 시 /AICC 페이지로 리다이렉트
            //console.log("로그인 성공!")
            //console.log(result);
            
            /* url 데이터로 화면 분기 */
            // TODO Main 으로 이동 후 상담실/콜센터 세부 이동
            if (result.url === null) {
                console.log("별도 URL 접근 설정이 안되어 있습니다.")
                showModal('현재 접근 권한이 없습니다. 관리자에게 문의하세요.');
                return;
            }
            
            console.log("Main 페이지로 이동")
            navigate('/main', { state: { user: result } });
            /*
            if (result.url === 'aicc') {
                // 콜센터
                navigate(URL_AICC, { state: { user: result } });
            } else if (result.url === 'aica') {
                // 상담실, 진료실
                navigate(URL_AICA, { state: { user: result } });
            } else {
                // 1. 기본 Alert 표시   
                // alert('현재 접근 권한이 없습니다. 관리자에게 문의하세요.');
                
                // 2. 모달 표시
                //setIsModalOpen(true);

                // 별도 URL 접근 설정이 안되어 있을 경우 기본 화면 표시
                console.log("별도 URL 접근 설정이 안되어 있습니다.")
                showModal('현재 접근 권한이 없습니다. 관리자에게 문의하세요.');
            }
            */
        } else {
            console.log("로그인 실패...")
            showModal('아이디 또는 패스워드가 올바르지 않습니다.');
            // 인증 실패 시 오류 메시지 표시
            setError(`로그인 실패... (err : ${result.message})`);
        }
    };

    // 화면 렌더링
    return (
        <div className="login-container">
            <form onSubmit={handleLogin}>
                <h2>POPBIO</h2>
                <div className="form-group">
                    <label>아이디</label>
                    <input
                        type="text"
                        value={userId}
                        onChange={handleIdChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>비밀번호</label>
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>
                <button type="submit" className="login-button">로그인</button>
            </form>

            <Modal 
                isOpen={isModalOpen} 
                onRequestClose={() => setIsModalOpen(false)} 
                className="modal-content"
            >
                <h2 className="modal-title">알림</h2>
                <p className="modal-message">{modalMessage}</p>
                <button className="modal-button" onClick={() => setIsModalOpen(false)}>닫기</button>
            </Modal>
        </div>
    );
};

export default Login;